import { useState } from "react";
import Fade from "react-reveal/Fade";

import Modal from "./Modal";

import css from "./index.module.css";

const medias = [
  "/medias/jj_5.jpg",
  "/medias/jj_1.jpg",
  "/medias/jj_3.jpg",
  "/medias/jj_2.jpg",
  "/medias/jj_4.jpg",
];

function Medias() {
  return medias.map((media) => (
    <Fade>
      <img className={css.media} src={media} alt="" />
    </Fade>
  ));
}

export default function Assistance() {
  const [showCallModal, setShowCallModal] = useState(false);
  const [showBringModal, setShowBringModal] = useState(false);

  const handleToggleShowCallModal = () => setShowCallModal((value) => value);
  const handleToggleShowBringModal = () => setShowBringModal((value) => value);

  return (
    <div className={css.container}>
      <div className={css.content}>
        <h1>How can we help?</h1>
        <button className={css.entrance} onClick={handleToggleShowBringModal}>
          <h2>Bring another size / color →</h2>
        </button>

        <button className={css.entrance} onClick={handleToggleShowCallModal}>
          <h2>Send staff over →</h2>
        </button>

        <section className={css.outfits}>
          <h3 className={css.accent}>Need inspiration?</h3>
          <h3>Outfit ideas</h3>
          <div className={css.medias}>
            <Medias />
          </div>
        </section>
      </div>
      {showCallModal && (
        <Modal onClick={handleToggleShowCallModal}>
          <h1>Help is on the way!</h1>
          <h3 className={css.accent}>
            Julia will arrive shortly to assist you.
          </h3>
          <h3>
            ETA 3 minutes
          </h3>
        </Modal>
      )}

      {showBringModal && (
        <Modal onClick={handleToggleShowBringModal}>
          <h1>Please scan the barcode</h1>
          <h3 className={css.accent}>
            We will bring the selected items to your fitting room
          </h3>
        </Modal>
      )}
    </div>
  );
}
