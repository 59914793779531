import { Route } from "wouter";

import Assistance from "./views/Assistance";

function App() {
  return (
    <div className="App">
      <Route path="/">
        <Assistance />
      </Route>
    </div>
  );
}

export default App;
