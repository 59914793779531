import css from "./Modal.module.css";

type Props = {
  onClick(): void;
  children: React.ReactNode;
};

export default function Modal({ onClick, children }: Props) {
  return (
    <div className={css.container} onClick={onClick}>
      {children}
    </div>
  );
}
